import // getCommonParamsItemLabel
// getGlobalParamsListItemLabel
'@/utils/common'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'general.name'
    },
    // {
    //   prop: 'code',
    //   label: 'profile.code'
    // },
    // {
    //   prop: 'batchType',
    //   label: 'param.batch',
    //   formatter: (row) => {
    //     return getCommonParamsItemLabel(row, 'batch_type')
    //   }
    // },
    // {
    //   prop: 'terminalId',
    //   label: 'param.terminal'
    //   // formatter: (row) => {
    //   //   return getGlobalParamsListItemLabel(row, 'terminalList', {
    //   //     isNeedPreFix: false,
    //   //     labelParams2: 'sn'
    //   //   })
    //   // }
    // },
    // {
    //   prop: 'tenant',
    //   label: 'param.tenant'
    //   // formatter: (row) => {
    //   //   return getGlobalParamsListItemLabel(row, 'tenantList', {
    //   //     isNeedPreFix: false
    //   //   })
    //   // }
    // },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time'
    },
    {
      label: 'common.app-operation',
      width: '400',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}

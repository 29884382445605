<template>
  <div class="applications">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-template-group')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @editBtnClick="handleEditData"
      @viewBtnClick="handleViewData"
      @newBtnClick="handleNewData"
      @pageInfoChange="pageInfoChangeAction"
    ></page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'

import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'

import { useRouter } from 'vue-router'

// 表格基础配置项
const tableOptions = {
  moduleName: 'parameter', // 所属的模块
  pageName: 'templateGroup', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true, // 是否显示添加按钮的插槽
  pageAction: 'param/getTemplateGroupList', // 获取表格的Action
  deleteRecordAction: 'param/deleteTemplateGroupById', // 删除record的Action
  pageCountGetter: 'param/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'param/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

const pageContentRef = ref('')
const pageSearchRef = ref('')

const handleQueryClick = (queryInfo) => {
  // 调用page-search中的getPageData方法
  queryInfo.tenantId = queryInfo.tenantId && queryInfo.tenantId.join(',')
  pageContentRef.value.getPageData(queryInfo)
}
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}
const router = useRouter()
const handleViewData = (id) => {
  router.push(`/template/group/view/${id}`)
}

const handleEditData = (id) => {
  router.push(`/template/group/edit/${id}`)
}

const handleNewData = () => {
  router.push(`/template/group/new`)
}
const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  formData.tenantId = formData.tenantId && formData.tenantId.join(',')
  pageContentRef.value.getPageData(formData)
}
</script>

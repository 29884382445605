import // getCommonParamsOptions,
// getGlobalParamsOptionsAsync,
// getParamsOptionsAsync
'@/utils/common'
export const searchFormConfig = {
  // colLayout: {
  //   span: 8
  // },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name'
    },
    // {
    //   field: 'code',
    //   type: 'input',
    //   label: 'profile.code'
    // },
    // {
    //   field: 'batchType',
    //   type: 'select',
    //   label: 'param.batch',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('batch_type')
    // },
    // {
    //   field: 'terminalId',
    //   type: 'select',
    //   label: 'param.terminal',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () =>
    //     getGlobalParamsOptionsAsync('terminalList', {
    //       isNeedPreFix: false,
    //       labelParams2: 'sn'
    //     })
    // },
    // {
    //   field: 'tenant',
    //   type: 'treeSelect',
    //   label: 'file.tenant',
    //   otherOptions: {
    //     props: {
    //       multiple: true
    //     }
    //   },
    // isResolveGlobalParams: true,
    // options: [],
    // handler: () => getParamsOptionsAsync('tenantList'),    // }
    // {
    //   field: 'description',
    //   type: 'input',
    //   label: 'general.description'
    // },
    {
      field: 'createTime',
      type: 'datepicker',
      label: 'general.creation-date',
      otherOptions: {
        type: 'daterange'
      },
      dateFieldName: ['startDateTime', 'endDateTime']
    }
  ]
}
